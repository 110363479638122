import Lightpick from 'lightpick';

export default class SimpleDatePicker {
    constructor(input) {
        this.input = input
        this.init()
    }

    init() {
        new Lightpick({
            field: this.input,
            singleDate: true,
            format: 'DD.MM.YYYY',
        })
    }
}
